<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="67" fill="none" viewBox="0 0 62 67">
    <g :filter="`url(#filter0_d-${uniqueID})`">
      <path
        fill="#C9CFDC"
        d="M10 33.11c0-11.599 9.402-21 21-21s21 9.401 21 21v13c0 4.418-3.582 8-8 8H31c-11.598 0-21-9.403-21-21z"
      />
    </g>
    <path
      fill="#8D93A8"
      fill-rule="evenodd"
      d="M35.39 20.833c.17-.635-.65-1.049-1.06-.536l-10.207 12.77c-.273.341-.105.85.317.964l5.966 1.599-2.796 10.434c-.17.634.65 1.048 1.06.535l10.206-12.77c.273-.34.106-.85-.317-.964l-5.965-1.598 2.795-10.434z"
      clip-rule="evenodd"
    />
    <defs>
      <filter
        :id="'filter0_d-' + uniqueID"
        width="62"
        height="62"
        x="0"
        y="4.109"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      uniqueID: Math.random(),
    };
  },
};
</script>
