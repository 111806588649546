<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="67" fill="none" viewBox="0 0 63 67">
    <g :filter="`url(#filter0_d-${uniqueID})`">
      <path
        fill="#122771"
        d="M10.828 33.175c0-11.598 9.402-21 21-21s21 9.402 21 21v13c0 4.419-3.582 8-8 8h-13c-11.598 0-21-9.402-21-21z"
      />
    </g>
    <path
      :fill="`url(#paint0_linear_live_updated-${uniqueID})`"
      fill-rule="evenodd"
      d="M36.217 20.898c.17-.634-.649-1.048-1.06-.535l-10.205 12.77c-.274.34-.106.85.316.963l5.966 1.6-2.796 10.433c-.17.635.65 1.049 1.06.536l10.206-12.77c.273-.341.106-.85-.316-.964l-5.966-1.599 2.795-10.434z"
      clip-rule="evenodd"
    />
    <defs>
      <linearGradient
        :id="'paint0_linear_live_updated-' + uniqueID"
        x1="39.837"
        x2="24.988"
        y1="33.514"
        y2="31.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#35BDD1" />
        <stop offset="1" stop-color="#D7DF38" />
      </linearGradient>
      <filter
        :id="'filter0_d-' + uniqueID"
        width="62"
        height="62"
        x=".828"
        y="4.175"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  // props: {
  //   uniqueID: { type: Number, required: true },
  // },
  data() {
    return {
      uniqueID: Math.random(),
    };
  },
};
</script>

