<template>
  <svg width="47" height="49" viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :filter="`url(#filter0_d-${uniqueID})`">
      <path
        d="M7.73853 24.4501C7.73853 15.8854 14.6816 8.94238 23.2462 8.94238V8.94238C31.8109 8.94238 38.7539 15.8854 38.7539 24.4501V34.0501C38.7539 37.3128 36.1089 39.9578 32.8462 39.9578H23.2462C14.6816 39.9578 7.73853 33.0147 7.73853 24.4501V24.4501Z"
        fill="#122771"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.1165 15.3777C26.2445 14.912 25.644 14.6096 25.3362 14.9849L17.8118 24.1597C17.605 24.4119 17.726 24.7892 18.0401 24.8712L22.4387 26.0199L20.3761 33.5221C20.248 33.9878 20.8486 34.2902 21.1564 33.9149L28.6808 24.7401C28.8876 24.4879 28.7666 24.1106 28.4525 24.0286L24.0539 22.8799L26.1165 15.3777Z"
      fill="#FBFBFF"
    />
    <defs>
      <filter
        :id="'filter0_d-' + uniqueID"
        x="0.35391"
        y="3.03469"
        width="45.7846"
        height="45.7849"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.47692" />
        <feGaussianBlur stdDeviation="3.69231" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5316_48232" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5316_48232" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      uniqueID: Math.random(),
    };
  },
};
</script>
