<template>
  <div class="locate-charger-v2" ref="locateCharger">
    <h1 class="sr-only">Locate a Charger</h1>
    <button
      v-if="markers"
      class="button sr-only sr-only-focusable skip-to-search-btn"
      @click="skipToSearch"
      :tabindex="isMobile && (isStationListExpanded || isStationDetailExpanded) ? '-1' : '0'"
    >
      Skip to search <span class="sr-only">for an address or location</span>
    </button>
    <div class="map-wrapper" :class="{ 'map-wrapper--station-details-expanded': isStationDetailExpanded }" ref="map">
      <button
        v-if="markers"
        class="button sr-only sr-only-focusable"
        @click="skipToSearch"
        :tabindex="isMobile && (isStationListExpanded || isStationDetailExpanded) ? '-1' : '0'"
      >
        Skip to search <span class="sr-only">for an address or location</span>
      </button>
      <h3 class="sr-only">Map</h3>
      <MapLoader apiKey="AIzaSyD2Wl8iyEHWZM40TK95gVo-DWFGix6TAOA">
        <template slot-scope="{ google, map }">
          <div class="map-markers" v-if="markers.length > 0 || $route.query.search !== ''">
            <div v-for="(marker, index) in markers" :key="index">
              <MapMarker
                class="testing"
                @stationDetailOpen="stationDetailOpen"
                @updateIcon="updateIcon"
                :active="marker.active"
                :google="google"
                :iconSize="iconSize"
                :marker="marker"
                :map="map"
                ref="googleMarker"
                :class="{ 'display-none': liveMarker }"
              />
            </div>
          </div>
          <MapCurrentLocation :currentLocCoords="currentLocCoords" :google="google" :map="map" />
          <MapZoom :map="map" />
        </template>
      </MapLoader>
    </div>
    <Legend />
    <h2 class="sr-only">Search for a location</h2>
    <Search
      v-if="!isStationDetailExpanded"
      :markersRetrieved="markers && markers.length > 0"
      :selectedStation="selectedStation"
      :isStationDetailExpanded="isStationDetailExpanded"
      v-on:stationDetailClose="stationDetailClose"
      v-on:stationReset="stationReset"
    />
    <div
      class="map-panel-wrapper"
      :class="{ 'map-panel-wrapper--station-details-expanded': isStationDetailExpanded }"
      ref="panelWrapper"
    >
      <StationDetail
        v-if="isStationDetailExpanded"
        :selectedStation="selectedStation"
        :isSelectedStationDetailsLoaded="isSelectedStationDetailsLoaded"
        v-on:stationDetailClose="stationDetailClose"
      />
      <StationFilters v-show="isFilterPanelExpanded" />
      <StationResults v-show="!isStationDetailExpanded" @stationDetailOpen="stationDetailOpen" />
    </div>
    <ListView />
    <ScrollToTop />
  </div>
</template>

<script>
import Legend from '@/components/LocateCharger/Legend.vue';
import ListView from '@/components/LocateCharger/ListView.vue';
import MapCurrentLocation from '@/components/LocateCharger/MapCurrentLocation.vue';
import MapLoader from '@/components/LocateCharger/MapLoader.vue';
import MapMarker from '@/components/LocateCharger/MapMarker.vue';
import MapZoom from '@/components/LocateCharger/MapZoom.vue';
import ScrollToTop from '@/components/LocateCharger/ScrollToTop.vue';
import Search from '@/components/LocateCharger/Search.vue';
import StationDetail from '@/components/LocateCharger/StationDetail.vue';
import StationFilters from '@/components/LocateCharger/StationFilters.vue';
import StationResults from '@/components/LocateCharger/StationResults.vue';
import States from '@/assets/json/us_states_and_territories.json';

export default {
  name: 'LocateCharger',
  props: [],
  components: {
    Legend,
    ListView,
    MapCurrentLocation,
    MapLoader,
    MapMarker,
    MapZoom,
    ScrollToTop,
    Search,
    StationDetail,
    StationFilters,
    StationResults,
  },
  metaInfo() {
    return {
      google: null,
      map: null,
      title: this.pageTitle,
      meta: [
        {
          name: 'description',
          content: this.pageDescription,
        },
      ],
      link: [{ rel: 'canonical', href: this.canonicalUrl }],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            additionalType: 'https://en.wikipedia.org/wiki/Charging_station',
            URL: this.stationMetaURL,
            logo: 'https://www.electrifyamerica.com/529c87597122c42e901ffc3eab9e4be7.svg',
            name: this.stationMetaName,
            hasMap: this.stationMetaHasMap,
            address: this.stationMetaAddress,
            geo: this.stationMetaGEO,
            amenityFeature: this.stationMetaAmenityFeature,
          },
        },
      ],
    };
  },
  data() {
    return {
      CCS: 0,
      iconSize: 20,
      liveMarker: null,
      menuOpen: null,
      publicPath: process.env.BASE_URL,
      site: process.env.VUE_APP_SITE,
      defaultPageTitle: 'Locate a public EV charger | Electrify America',
      defaultPageDescription:
        'Learn about our U.S. electric vehicle (EV) charging network, located along routes from coast to coast. Find the Electrify America station closest to you.',
      pageTitle: '',
      pageDescription: '',
      canonicalUrl: 'https://www.electrifyamerica.com/locate-charger/',
    };
  },
  created() {
    this.pageTitle = this.defaultPageTitle;
    this.pageDescription = this.defaultPageDescription;
    this.$store.dispatch('locations/getAllLocations', this.$router);
  },
  mounted() {
    document.body.setAttribute('data-route', this.$route.name);
    this.$root.$on('stationDetailOpen', (station) => {
      this.stationDetailOpen(station);
    });
    this.$root.$on('zoomToCityorState', (stateAbbr) => {
      this.zoomToCityorState(stateAbbr);
    });
  },
  beforeDestroy() {
    document.body.removeAttribute('data-route', this.$route.name);
  },
  methods: {
    // I dont think state is correct here - state is a string abreviation ie 'VA', not a list
    async zoomToCityorState(state) {
      let paramResults = [];
      let result;
      if (this.$route.params.city && this.$route.params.state) {
        paramResults = await this.$store.getters['map/getPlacePredictions'](
          `${this.$route.params.city}, ${this.$route.params.state}`
        );
        result = paramResults.filter((r) => r.main?.toLowerCase() === this.$route.params.city.toLowerCase());
        // if the city name is invalid then zoom to the state level
        if (result.length === 0) {
          paramResults = await this.$store.getters['map/getPlacePredictions'](state[0].name);
          result = paramResults.filter((r) => r.main?.toLowerCase() === state[0].name.toLowerCase());
        }
      } else if (!this.$route.params.city) {
        paramResults = await this.$store.getters['map/getPlacePredictions'](state[0].name);
        result = paramResults.filter((r) => r.main?.toLowerCase() === state[0].name.toLowerCase());
      }
      if (result) {
        this.$store.dispatch('map/goToLocation', result[0].id).then(() => {
          this.$nextTick(() => {
            paramResults = [];
            this.$store.commit('map/setIsSearchActive', false);
            this.$store.commit('map/setSearchInput', null);
            this.$root.$emit('expandStationList');
          });
        });
      } else {
        this.$router.push({ name: `locate-charger-en` });
      }
    },
    // Functionality to change icon when selected
    resetIcons() {
      this.$refs.googleMarker.forEach((marker) => {
        marker.setIcon();
      });
    },

    skipToSearch() {
      this.$root.$emit('focusSearchInput');
    },

    stationDetailClose() {
      this.$store.commit('map/setSelectedStation', null);
      window.history.replaceState('', '', '/locate-charger/');
      this.pageTitle = this.defaultPageTitle;
      this.pageDescription = this.defaultPageDescription;

      this.$store.commit('map/setIsStationDetailExpanded', false);
      this.$store.commit('map/setIsStationListExpanded', true);
      this.$store.commit('locations/setUniqueLocation', null);
      this.$store.commit('locations/setUniqueRoute', null);

      this.resetIcons();
      this.$store
        .dispatch('map/updateMapZoomAndCenter', {
          zoom: this.$store.state.map.priorMapZoom,
          center: this.$store.state.map.priorMapCenter,
        })
        .then(() => {
          if (this.$store.state.map.mapZoom >= 12) {
            this.$root.$emit('expandStationList');
          } else {
            this.$root.$emit('closeStationList');
          }
        });
    },

    stationDetailOpen(marker) {
      this.$store.commit('map/setIsStationDetailExpanded', false);
      this.$store.commit('map/setIsSelectedStationDetailsLoaded', false);
      this.$store.commit('map/setSearchInput', null);

      this.$store.dispatch('locations/getLocationDetail', marker.id).then(() => {
        marker = this.$store.getters['locations/getLocationMarkerById'](marker.id);
        const state = States.filter(
          (s) =>
            s.name.toLowerCase() === marker.state.toLowerCase() ||
            s.abbreviation.toLowerCase() === marker.state.toLowerCase()
        );
        const route = `/locate-charger/${state[0].abbreviation.toLowerCase()}/${marker.city
          .replace(/\s/g, '-')
          .toLowerCase()}/${marker.address
          .replace(/\s/g, '-')
          .replace(/&/g, '&amp;')
          .replace(/[<>#%|'’.]/g, '')
          .toLowerCase()}/${marker.id}/`;
        this.pageTitle = `Electrify America in ${marker.city}, ${state[0].abbreviation}, ${marker.address}`;
        this.pageDescription = `Find an Electrify America charging station at ${marker.address}, ${marker.city}, ${state[0].abbreviation}. Start charging with the largest public fast charging network in the U.S. today!`;
        this.canonicalUrl = `'https://www.electrifyamerica.com${route}`;
        const url = window.location.href;
        if (url.includes('?') && !url.includes('search')) {
          const routeWithQuery = `${route}?${url.split('?')[1]}`;
          window.history.replaceState('', '', encodeURI(routeWithQuery));
          this.canonicalUrl = `'https://www.electrifyamerica.com${routeWithQuery}`;
        } else {
          window.history.replaceState('', '', encodeURI(route));
          this.canonicalUrl = `'https://www.electrifyamerica.com${route}`;
        }
        this.$store.commit('locations/setUniqueRoute', route);

        this.$store.commit('map/setRecentlyViewed', marker.id);
        this.$store.dispatch('map/updateMapZoomAndCenter', {
          zoom: 15,
          center: {
            lat: marker.position.lat,
            lng: marker.position.lng,
          },
        });
        if (
          Object.keys(this.$store.state.locations.error).length === 0 &&
          this.$store.state.locations.error.constructor === Object
        ) {
          marker = this.$store.getters['locations/getLocationMarkerById'](marker.id);
          marker.markerSelected = true;
          this.$store.commit('map/setSelectedStation', marker);
          this.$store.commit('map/setIsStationDetailExpanded', true);
          this.$store.commit('map/setIsSelectedStationDetailsLoaded', true);
          setTimeout(() => {
            this.updateIcon(marker);
            this.$root.$emit('expandStationList');
            this.$store.commit('map/setIsFilterPanelExpanded', false);
          }, 400);
        } else {
          this.$root.$emit('expandStationList');
        }
      });
    },

    stationReset() {
      this.$store.commit('map/setIsStationDetailExpanded', false);
      this.$store.commit('map/setIsSelectedStationDetailsLoaded', false);
    },

    updateIcon(markerComp) {
      const selectedMarker = this.$refs.googleMarker.find((marker) => marker.marker.id === markerComp.id);
      if (selectedMarker) {
        selectedMarker.setIcon();
      }
    },
  },
  computed: {
    searchInput() {
      return this.$store.state.map.searchInput;
    },
    uniqueRoute() {
      return this.$store.state.locations.uniqueRoute;
    },
    currentLocCoords() {
      return this.$store.state.map.currentLocCoords;
    },
    isFilterPanelExpanded() {
      return this.$store.state.map.isFilterPanelExpanded;
    },
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    isStationDetailExpanded() {
      return this.$store.state.map.isStationDetailExpanded;
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
    isSelectedStationDetailsLoaded() {
      return this.$store.state.map.isSelectedStationDetailsLoaded;
    },
    markers() {
      return this.$store.state.locations.locationMarkers;
    },
    recentlyViewed() {
      return this.$store.state.map.recentlyViewed;
    },
    stationMetaName() {
      if (this.selectedStation === null) {
        return 'Electrify America';
      }
      if (this.selectedStation.address) {
        return `Electrify America ${this.selectedStation.address} ${this.selectedStation.city}, ${this.selectedStation.state} ${this.selectedStation.zipCode}`;
      }
      return 'Electrify America';
    },
    stationMetaAddress() {
      if (this.selectedStation === null) {
        return '';
      }
      if (this.selectedStation.address) {
        const stationAddress = {
          '@type': 'PostalAddress',
          addressLocality: `${this.selectedStation.city}`,
          addressRegion: `${this.selectedStation.state}`,
          postalCode: `${this.selectedStation.zipCode}`,
          streetAddress: `${this.selectedStation.address}`,
        };
        return stationAddress;
      }
      return '';
    },
    stationMetaURL() {
      if (this.selectedStation === null) {
        return `https://www.electrifyamerica.com/locate-charger/`;
      }
      if (this.selectedStation.address) {
        return `https://www.electrifyamerica.com${this.uniqueRoute}`;
      }
      return 'https://www.electrifyamerica.com/locate-charger/';
    },
    stationMetaGEO() {
      if (this.selectedStation === null) {
        return '';
      }
      if (this.selectedStation.position) {
        const stationGEO = {
          '@type': 'GeoCoordinates',
          latitude: `${this.selectedStation.position.lat}`,
          longitude: `${this.selectedStation.position.lng}`,
        };
        return stationGEO;
      }
      return '';
    },
    stationMetaAmenityFeature() {
      const amenities = [];
      if (this.selectedStation === null) {
        return amenities;
      }
      if (this.selectedStation.isCcs) {
        amenities.push({
          '@type': 'LocationFeatureSpecification',
          additionalType: 'https://en.wikipedia.org/wiki/Combined_Charging_System',
          name: 'Combined Charging System',
          value: 'True',
        });
      }
      if (this.selectedStation.isChademo) {
        amenities.push({
          '@type': 'LocationFeatureSpecification',
          additionalType: 'https://en.wikipedia.org/wiki/CHAdeMO',
          name: 'CHAdeMO',
          value: 'True',
        });
      }
      if (this.selectedStation.isL2) {
        amenities.push({
          '@type': 'LocationFeatureSpecification',
          additionalType: 'https://en.wikipedia.org/wiki/Type_2_connector',
          name: 'Type 2 Connector',
          value: 'True',
        });
      }
      return amenities;
    },
    stationMetaHasMap() {
      if (this.selectedStation === null) {
        return '';
      }
      if (this.selectedStation.name) {
        return `https://www.google.com/maps/search/?api=1&query=${this.selectedStation.name.replace(/\s/g, '+')}`;
      }
      return '';
    },
    selectedStation() {
      return this.$store.state.map.selectedStation;
    },
  },
};
</script>

<style lang="scss">
.locate-charger-v2 {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-height: 570px;
  overflow-x: hidden;
  position: relative;

  @media (min-width: 750px) {
    align-items: flex-end;
  }

  .map-close-button {
    background-color: $c-primary-background;
    border: none;
    border-radius: 16px;
    box-shadow: 0 5px 17px 0 rgba(20, 25, 43, 0.3);
    padding: 0;
    padding-top: 4px;
    position: absolute;
    right: 14px;
    top: -70px;
    width: 50px;
    z-index: 98;

    svg {
      height: 37.5px;
      width: 37.5px;
    }
  }

  /* Map Styles */
  .map-wrapper {
    height: calc(100vh - 140px);
    margin: 0;
    min-height: 50vh;
    order: 2;
    width: 100%;
    z-index: 10;

    @media (max-width: 749px) {
      &--station-details-expanded {
        height: calc(50vh - 140px);
      }
    }

    @media (min-width: 750px) {
      height: calc(100vh - 150px);
      min-height: 500px;
      order: 1;
    }
    @media (max-height: 355px) {
      height: calc(100vh + 165px);
    }
  }

  /* Station List/Detail Panel styles */
  .map-panel-wrapper {
    align-items: flex-end;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 5px 17px 0 rgba(20, 25, 43, 0.3);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    left: 0;
    max-height: calc(100vh - 80px);
    overflow: auto;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 90;

    @media (min-width: 750px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      left: 16px;
      max-height: calc(100vh - 246px);
      top: 82px;
      width: 475px;

      &--station-details-expanded {
        border-radius: 8px;
        max-height: calc(100vh - 180px);
        top: 16px;
      }
    }

    a,
    button {
      font-weight: $fw-medium;
      letter-spacing: initial;
    }
  }

  .map-panel-headline {
    border-top: 1px solid #dde2e8;
    color: $c-gray-dark;
    font-size: 13px;
    font-weight: $fw-regular;
    margin: 6px 0;
    padding: 8px 0;
    position: relative;
    text-transform: uppercase;

    &:before {
      content: '';
      background-color: #149154;
      height: 1px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 15px;
    }
  }
}
[data-route='locate-charger-en'] .embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceSidebar.layout-docked .dockableContainer {
  @media (min-width: 750px) and (max-width: 1200px) {
    bottom: 70px;
  }
}

.skip-to-search-btn.sr-only-focusable:active,
.skip-to-search-btn.sr-only-focusable:focus {
  @media (max-width: 750px) {
    position: relative;
  }
}
</style>
