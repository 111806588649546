<template>
  <div>
    <div v-if="locationLoading" class="loading">
      <span class="circle"></span>
    </div>
    <button
      :class="{ 'menu-open': isStationListExpanded, 'menu-closed': !isStationListExpanded }"
      :tabindex="isMobile && isStationListExpanded ? '-1' : '0'"
      @click="toggleLocation"
      class="current-location"
      aria-label="Share your current location"
    >
      <LocationIcon class="location" />
    </button>
  </div>
</template>

<script>
import LocationIcon from '@/assets/images/Map/share_location.svg';

export default {
  name: 'MapCurrentLocation',
  props: ['google', 'map'],
  components: { LocationIcon },
  data() {
    return {
      locationLoading: null,
      locationOn: null,
      m: null,
    };
  },
  computed: {
    currentLocCoords: {
      get() {
        return this.$store.state.map.currentLocCoords;
      },
    },
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
  },
  methods: {
    success(position) {
      this.$store.dispatch('map/setZoom', 8);
      this.$store.commit('map/setCurrentLocCoords', position);
      this.locationLoading = !this.locationLoading;
    },
    error() {
      // Disabling to alert the user to the problem.
      // eslint-disable-next-line no-alert
      alert('Please Enable Location Services');
      this.locationLoading = false;
      this.locationOn = !this.locationOn;
    },
    toggleLocation() {
      this.locationOn = !this.locationOn;
      if (this.locationOn) {
        if (this.currentLocCoords) {
          this.$store.dispatch('map/updateMapZoomAndCenter', {
            zoom: 8,
            center: this.currentLocCoords,
          });
        } else if (navigator.geolocation) {
          this.locationLoading = !this.locationLoading;
          navigator.geolocation.getCurrentPosition(this.success, this.error, { timeout: 10000 });
        }
      } else {
        this.$store.dispatch('map/setZoom', 5);
        this.$store.commit('map/setCurrentLocCoords', null);
        if (this.m !== null) {
          this.m.setMap(null);
          this.m = null;
        }
      }
    },
  },
  watch: {
    currentLocCoords() {
      if (this.currentLocCoords) {
        // Creating the marker
        const icon = document.createElement('img');
        icon.src = require('@/assets/images/Map/locator.svg?external');
        icon.width = '35';
        icon.height = '35';

        this.m = new this.google.maps.marker.AdvancedMarkerElement({
          position: this.currentLocCoords,
          map: this.map,
          content: icon,
        });
      }
    },
  },
  beforeDestroy() {
    if (this.m !== null) {
      this.m.setMap(null);
    }
  },
};
</script>

<style lang="scss" scoped>
.current-location {
  align-items: center;
  background-color: $c-primary-background;
  border-radius: 16px;
  bottom: 250px;
  box-shadow: 0 5px 17px 0 rgba(20, 25, 43, 0.3);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: 15px;
  width: 50px;
  z-index: 99;

  @media (min-width: 750px) {
    right: 36px;
    top: 16px;
  }
}
button {
  border-radius: 16px;
  border: none;
}
.location {
  height: 32px;
  width: 32px;
}
.loading {
  @keyframes spin {
    to {
      transform: rotateZ(360deg);
    }
  }

  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 110;

  .circle {
    animation: spin ease 1000ms infinite;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: $c-primary-background;
    display: block;
    height: 60px;
    margin: 0 auto;
    width: 60px;
  }
}

.menu-open {
  @media (max-width: 750px) {
    transform: translateX(85px);
    transition: 500ms ease all;
  }
}

.menu-closed {
  @media (max-width: 750px) {
    transform: translateX(0px);
    transition: 500ms ease all;
  }
}
</style>
