<template>
  <div class="scroll-to-top__container" :class="{ 'scroll-to-top__container--visible': showBackToTopButton }">
    <button @click="scrollToTop()" class="scroll-to-top__button" aria-label="Scroll to top" ref="backToTop">
      <ScrollToTopImage />
    </button>
  </div>
</template>

<script>
import ScrollToTopImage from '@/assets/images/Map/back-to-top.svg';

export default {
  name: 'ScrollToTop',
  components: {
    ScrollToTopImage,
  },
  data() {
    return {
      showBackToTopButton: false,
    };
  },
  mounted() {
    if (this.$parent.$refs.panelWrapper) {
      this.$parent.$refs.panelWrapper.addEventListener('scroll', this.toggleBackToTopButtonDisplay);
    }
  },
  methods: {
    scrollToTop() {
      this.$parent.$refs.panelWrapper.scrollTop = 0;
    },
    toggleBackToTopButtonDisplay() {
      if (this.isMobile) {
        const cutoffPoint = (window.innerHeight - 80) * 0.4;
        if (this.$parent.$refs.panelWrapper.scrollTop >= cutoffPoint) {
          this.showBackToTopButton = true;
        } else {
          this.showBackToTopButton = false;
        }
      }
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-to-top {
  &__container {
    bottom: 50px;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    position: absolute;
    right: 50px;
    transition: opacity 1s;
    visibility: hidden;
    z-index: 0;

    &--visible {
      opacity: 1;
      visibility: visible;
      z-index: 90;
    }
    @media (min-width: 750px) {
      display: none;
    }
  }

  &__button {
    align-items: center;
    background-color: rgba(251, 251, 255, 0.5);
    border: 2px solid $c-highlights;
    border-radius: 50%;
    box-shadow: 40px 250px 250px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    height: 58px;
    margin: 0;
    padding: 0;
    width: 58px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
</style>
