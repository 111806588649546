<script>
import MarkerConstants from '@/constants/mapMarkers';

export default {
  props: ['iconSize', 'google', 'map', 'marker', 'active'],
  data() {
    return {
      m: {},
    };
  },
  mounted() {
    // Creating the marker
    const icon = document.createElement('img');
    icon.src = MarkerConstants.iconIsLive;
    icon.width = '20';
    icon.height = '20';
    this.m = new this.google.maps.marker.AdvancedMarkerElement({
      title: `${this.marker.name} - ${this.marker.address}, ${this.marker.city}, ${this.marker.state}`,
      position: this.marker.position,
      map: this.map,
      content: icon,
      id: this.marker.id,
    });

    // Running condition to check for the type of charger
    // assign correct icon from above to each
    this.setIcon();

    // When clicking on a marker, we are opening the station details
    // centering map and zooming in automatically
    // and changing the icon to the selected version
    this.m.addListener('click', () => {
      this.$emit('stationDetailOpen', this.marker);
    });
    // set the map markers visibility
    this.setVisible(this.active);
  },
  methods: {
    setVisible(visible) {
      this.m.position = visible ? this.marker.position : null;
    },
    setIcon() {
      const icon = document.createElement('img');

      // Set some defaults, just in case
      icon.src = MarkerConstants.iconIsLive;
      icon.width = '20';
      icon.height = '20';

      if (this.$store.state.map.mapZoom >= 6) {
        if (this.marker.isCommercialChargerLocation) {
          icon.src = MarkerConstants.iconCommercialLarge;
        } else if (this.marker.comingSoon) {
          icon.src = MarkerConstants.iconComingSoonLarge;
        } else if (this.marker.facility === 'SOLAR') {
          icon.src = MarkerConstants.iconL2Large;
        } else {
          icon.src = MarkerConstants.iconIsLiveLarge;
        }
        icon.width = '30';
        icon.height = '30';
      }
      if (this.$store.state.map.mapZoom < 6) {
        if (this.marker.isCommercialChargerLocation) {
          icon.src = MarkerConstants.iconCommercial;
        } else if (this.marker.comingSoon) {
          icon.src = MarkerConstants.iconComingSoon;
        } else if (this.marker.facility === 'SOLAR') {
          icon.src = MarkerConstants.iconL2;
        } else {
          icon.src = MarkerConstants.iconIsLive;
        }
        icon.width = '20';
        icon.height = '20';
      }
      if (this.selectedStation !== undefined && this.marker?.id === this.selectedStation?.id) {
        if (this.marker.isCommercialChargerLocation) {
          icon.src = MarkerConstants.iconCommercialSelected;
        } else if (this.marker.comingSoon) {
          icon.src = MarkerConstants.iconComingSoonSelected;
        } else if (this.marker.facility === 'SOLAR') {
          icon.src = MarkerConstants.iconL2Selected;
        } else {
          icon.src = MarkerConstants.iconIsLiveSelected;
        }
        icon.width = '30';
        icon.height = '30';
      }
      this.m.content = icon;
    },
  },
  computed: {
    mapZoom() {
      return this.$store.state.map.mapZoom;
    },
    selectedStation() {
      return this.$store.state.map.selectedStation;
    },
  },
  watch: {
    mapZoom(updatedZoom) {
      const icon = document.createElement('img');

      // Set some defaults, just in case
      icon.src = MarkerConstants.iconIsLive;
      icon.width = '20';
      icon.height = '20';

      if (updatedZoom >= 6) {
        if (this.marker.isCommercialChargerLocation) {
          icon.src = MarkerConstants.iconCommercialLarge;
        } else if (this.marker.comingSoon) {
          icon.src = MarkerConstants.iconComingSoonLarge;
        } else if (this.marker.isL2 && !this.marker.isCcs && !this.marker.isChademo) {
          icon.src = MarkerConstants.iconL2Large;
        } else {
          icon.src = MarkerConstants.iconIsLiveLarge;
        }
        icon.width = '30';
        icon.height = '30';
      }
      if (updatedZoom < 6) {
        if (this.marker.isCommercialChargerLocation) {
          icon.src = MarkerConstants.iconCommercial;
        } else if (this.marker.comingSoon) {
          icon.src = MarkerConstants.iconComingSoon;
        } else if (this.marker.isL2 && !this.marker.isCcs && !this.marker.isChademo) {
          icon.src = MarkerConstants.iconL2;
        } else {
          icon.src = MarkerConstants.iconIsLive;
        }
        icon.width = '20';
        icon.height = '20';
      }
      this.m.content = icon;
    },
    active() {
      this.setVisible(this.active);
    },
  },

  render() {
    return '';
  },
};
</script>
