<template>
  <div class="connector">
    <div class="connector__icon">
      <img loading="lazy" src="../../assets/images/Map/CCS@3x.png?external" alt="CCS Connector" v-if="type == 'CCS'" />
      <img
        loading="lazy"
        src="../../assets/images/Map/CHAdeMO@3x.png?external"
        alt="CHAdeMO Connector"
        v-if="type == 'CHAdeMO'"
      />
      <img
        loading="lazy"
        src="../../assets/images/Map/L2@3x.png?external"
        alt="Level 2 Connector"
        v-if="type == 'Level2'"
      />
    </div>
    <p class="connector__detail">
      <strong>{{ title }}</strong
      ><br />
      <template v-if="isVWHeadOffice"> 9.6 kW </template>
      <template v-else>{{ detail }}</template
      ><br />
      <template v-if="!isVWHeadOffice">
        <img loading="lazy" src="../../assets/images/Map/bolt-dark.svg?external" alt="" />
        <img
          loading="lazy"
          src="../../assets/images/Map/bolt-dark.svg?external"
          :class="{ dimmed: speed < 2 }"
          alt=""
        />
        <img
          loading="lazy"
          src="../../assets/images/Map/bolt-dark.svg?external"
          :class="{ dimmed: speed < 3 }"
          alt=""
        />
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'StationDetailConnector',
  props: ['connector', 'isVWHeadOffice'],
  data() {
    return {
      detail: '',
      speed: null,
      title: '',
      type: '',
    };
  },
  mounted() {
    const powerLevel = Math.trunc((this.connector.amperage * this.connector.voltage) / 1000);
    // Determine this connectors type
    if (this.connector.standard.toLowerCase().indexOf('chademo') > -1) {
      this.type = 'CHAdeMO';
    } else if (this.connector.standard.toLowerCase() === 'iec_62196_t1') {
      this.type = 'Level2';
    } else {
      this.type = 'CCS'; // IEC_62196_T1_COMBO
    }

    // Determine this connectors title and speed
    if (this.type === 'CHAdeMO') {
      this.title = 'CHAdeMO';
      this.speed = 1;
    } else if (this.type === 'Level2') {
      this.title = 'Level 2';
      this.speed = null;
    } else if (powerLevel > 150) {
      this.title = 'Hyper-Fast';
      this.speed = 3;
    } else {
      this.title = 'Ultra-Fast';
      this.speed = 2;
    }

    // Determine this connectors detail
    if (this.type === 'CHAdeMO' || this.type === 'Level2') {
      this.detail = `${powerLevel} kW`;
    } else {
      this.detail = `CCS, ${powerLevel} kW`;
    }
  },
};
</script>

<style lang="scss" scoped>
.connector {
  align-items: center;
  display: flex;
  float: left;
  padding: 3px 12px 0 0;
  width: calc(50% - 12px);

  img.dimmed {
    opacity: 0.3;
  }

  &__icon {
    height: 48px;
    width: 48px;

    img {
      max-width: 100%;
    }
  }

  &__detail {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
</style>
