<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="67" fill="none" viewBox="0 0 62 67">
    <g :filter="`url(#filter0_d-${uniqueID})`">
      <path
        fill="#122771"
        d="M10 33.078c0-11.598 9.402-21 21-21s21 9.402 21 21v13c0 4.418-3.582 8-8 8H31c-11.598 0-21-9.402-21-21z"
      />
    </g>
    <path
      :fill="`url(#paint0_linear-${uniqueID})`"
      d="M30.166 38.19v.803c0 .232-.036.39-.11.475-.061.073-.202.11-.423.11h-6.545c-.233 0-.392-.03-.478-.092-.073-.073-.11-.219-.11-.438v-11.94c0-.22.037-.36.11-.42.086-.074.245-.11.478-.11h1.067c.232 0 .386.036.46.11.085.06.128.2.128.42v10.498h4.89c.221 0 .362.042.423.127.074.074.11.226.11.457z"
    />
    <path
      :fill="`url(#paint1_linear-${uniqueID})`"
      d="M35.547 27.052c1.275 0 2.25.293 2.923.877.687.572 1.03 1.394 1.03 2.465v.547c0 .597-.141 1.126-.423 1.589-.282.45-.65.858-1.103 1.223-.441.365-.913.694-1.416.986l-1.47.877c-.43.255-.748.486-.956.693-.209.207-.344.408-.405.603-.049.195-.073.39-.073.584v.073h5.313c.22 0 .361.043.423.128.073.073.11.225.11.456v.84c0 .232-.037.39-.11.475-.062.073-.203.11-.423.11h-7.115c-.233 0-.392-.03-.478-.092-.074-.073-.11-.219-.11-.438v-.895c0-.73.11-1.357.33-1.88.233-.524.54-.968.92-1.333s.802-.682 1.268-.95l1.563-.894c.49-.28.925-.603 1.305-.968.38-.365.57-.846.57-1.442v-.183c0-1.071-.588-1.607-1.765-1.607-.588 0-1.023.122-1.305.366-.27.23-.447.633-.533 1.205-.025.146-.08.25-.166.31-.073.049-.208.073-.404.073h-1.048c-.233 0-.392-.036-.478-.11-.074-.072-.11-.225-.11-.456 0-.377.073-.755.22-1.132.147-.39.343-.706.589-.95.772-.766 1.881-1.15 3.327-1.15z"
    />
    <defs>
      <linearGradient
        :id="'paint0_linear-' + uniqueID"
        x1="39.5"
        x2="23.491"
        y1="33.078"
        y2="29.094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#35BDD1" />
        <stop offset="1" stop-color="#D7DF38" />
      </linearGradient>
      <linearGradient
        :id="'paint1_linear-' + uniqueID"
        x1="39.5"
        x2="23.491"
        y1="33.078"
        y2="29.094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#35BDD1" />
        <stop offset="1" stop-color="#D7DF38" />
      </linearGradient>
      <filter
        :id="'filter0_d-' + uniqueID"
        width="62"
        height="62"
        x="0"
        y="4.078"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      uniqueID: Math.random(),
    };
  },
};
</script>
