export default {
    iconIsLive: require('@/assets/images/Map/live.png'),
    iconL2: require('@/assets/images/Map/L2.png'),
    iconComingSoon: require('@/assets/images/Map/coming_soon.png'),
    iconCommercial: require('@/assets/images/Map/commercial.png'),

    // Selected Icons
    iconIsLiveSelected: require('@/assets/images/Map/live_selected.png'),
    iconL2Selected: require('@/assets/images/Map/L2_selected.png'),
    iconComingSoonSelected: require('@/assets/images/Map/coming_soon_selected.png'),
    iconCommercialSelected: require('@/assets/images/Map/commercial_selected.png'),

    // Larger Icons when zoomed in
    iconIsLiveLarge: require('@/assets/images/Map/live.png'),
    iconL2Large: require('@/assets/images/Map/L2.png'),
    iconComingSoonLarge: require('@/assets/images/Map/coming_soon.png'),
    iconCommercialLarge: require('@/assets/images/Map/commercial.png'),
};
